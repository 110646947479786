import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { isAdmin } from 'src/Utils/Users'
import { userSelector } from 'src/redux/features/user/adapters'

export function PrivateRoute ({ children }) {
  const user = useSelector(userSelector)
  const navigate = useNavigate()
  const location = useLocation()

  React.useEffect(() => {
    if (user.loading) {
      return
    }
    if (!user.uid && !user.active) {
      navigate('/sign-in', { state: { from: location }, replace: true })
    } else if (location.pathname !== '/' && !isAdmin(user?.uid)) {
      navigate('/404', { replace: true })
    }
  }, [user, navigate, location])

  if (user.active && (location.pathname === '/' || isAdmin(user?.uid))) {
    return children
  }

  // Render nothing while waiting for the navigation to complete
  return null
}
