import React from 'react'
import { PageHeader } from 'src/components/Header/Page'

export function Stores () {
  return (
    <>
      <PageHeader title='Lojas' />
    </>
  )
}
