import React from 'react'
import './style.scss'
import {
  Container,
  Typography,
  Box,
  Tooltip,
  LinearProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Switch,
  CircularProgress,
  IconButton
} from '@mui/material'
import { Bolt, Timeline } from '@mui/icons-material'
import { getDatabase, onValue, ref } from 'firebase/database'
import { app } from 'src/firebase'
import { useFlags } from 'src/providers/Flags'
import { PageHeader } from 'src/components/Header/Page'
import { HttpClient } from 'src/Utils/HttpClient'
import { useTheme } from '@emotion/react'
import { isAdmin } from 'src/Utils/Users'
import { useSelector } from 'react-redux'
import { userSelector } from 'src/redux/features/user/adapters'
import { useNavigate } from 'react-router-dom'
import { PATHS } from 'src/Utils/Router'

export function Dashboard () {
  const theme = useTheme()
  const user = useSelector(userSelector)
  const {
    AC_SENSOR_ENABLE,
    DC_SENSOR_ENABLE,
    ELECTRIC_GENERATOR_SENSOR_ENABLE,
    THRESHOLD: { AC }
  } = useFlags()
  const navigate = useNavigate()

  const [logo, setLogo] = React.useState()
  const [stores, setStores] = React.useState([])
  const [genStatusSubmitting, setGenStatusSubmitting] = React.useState(false)
  const [loading, setLoading] = React.useState(true)

  const fetchSensorsData = React.useCallback(
    async () => {
      const db = getDatabase(app)
      const promises = user?.customers?.[0]?.stores.map(async store => {
        const storeRef = ref(db, `sensors/${store?.id}`)
        return new Promise((resolve, reject) => {
          onValue(storeRef, snapshot => {
            const data = snapshot.val()
            if (data) {
              data.store = store.name
              data.id = store.id
              data.genStatus = data?.r || data?.s || data?.t
              resolve(data)
            } else {
              resolve(null)
            }
          }, reject)
        })
      })
      if (promises && promises.length > 0) {
        setLoading(true)
        const storesData = await Promise.all(promises)
          .catch(error => {
            console.error('Error fetching data:', error) // More descriptive error message
          })
        setStores(storesData.filter(Boolean))
        setLoading(false)
      }
    },
    [user?.customers]
  )

  const toggleGenerator = React.useCallback(
    async (event, store) => {
      const status = (store?.r || store?.s || store?.t) && event?.target?.checked
      try {
        setGenStatusSubmitting(true)
        await HttpClient.put(`/stores/${store?.id}/generator`, { status: event?.target?.checked })
        setStores(prev => {
          const newStores = [...prev]
          const index = newStores.findIndex(s => s.id === store?.id)
          newStores[index].genStatus = status
          return newStores
        })
      } catch (error) {
        console.log(error)
      }
      setGenStatusSubmitting(false)
    },
    []
  )

  React.useEffect(() => {
    const logoPath = {
      bibe: require('@images/bibe_logo.svg'),
      paguemenos: require('@images/paguemenos_logo.png'),
      boa: require('@images/boa_logo.png')
    }[theme.tenant]
    setLogo(prev => logoPath && logoPath.default ? logoPath?.default : logoPath || prev)
  }, [theme.tenant])

  React.useEffect(() => {
    fetchSensorsData()
  }, [fetchSensorsData])

  return (
    <>
      {!isAdmin() && logo && (
        <Container className='dashboard-header-logo'>
          <img src={logo} alt='logo' />
        </Container>
      )}
      <PageHeader title='Dashboard' />
      <Container component='main' className='dashboard-container' maxWidth='xl'>
        {loading
          ? (
            <Box component={Paper} minWidth={700} className='loader'>
              <CircularProgress />
            </Box>
            )
          : (
              stores.length < 1
                ? (
                  <Box component={Paper} minWidth={700} p={2} className='no-stores'>
                    <Typography variant='h6' color='text.primary'>
                      Nenhuma loja encontrada
                    </Typography>
                  </Box>
                  )
                : (
                  <TableContainer component={Paper} className='stores-list'>
                    <Table sx={{ minWidth: 700 }} aria-label='customized table'>
                      <TableHead>
                        <TableRow>
                          <TableCell>Loja</TableCell>
                          {AC_SENSOR_ENABLE && <TableCell align='center'>Sistema de pressurização</TableCell>}
                          {DC_SENSOR_ENABLE && <TableCell align='center'>Alarme de incêndio</TableCell>}
                          {ELECTRIC_GENERATOR_SENSOR_ENABLE && <TableCell align='center'>Gerador de energia</TableCell>}
                          <TableCell align='right'>Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.values(stores).map((store, index) => (
                          <TableRow key={`${store}${index}`}>
                            <TableCell>
                              {store.store}
                            </TableCell>
                            {AC_SENSOR_ENABLE && (
                              <TableCell>
                                <Box className='pump-status'>
                                  <div className='power'>
                                    <Typography variant='body1' color='text.primary'>Rede</Typography>
                                    <Tooltip title={store.power}>
                                      <div className={`sensor-status ${store.power < AC ? 'green' : 'red'}`}>
                                        <Bolt />
                                      </div>
                                    </Tooltip>
                                  </div>
                                  <div className='emergency'>
                                    <Typography variant='body1' color='text.primary'>Emergência</Typography>
                                    <Tooltip title={store.emergency}>
                                      <div className={`sensor-status ${store.emergency < AC ? 'red' : 'green'}`}>
                                        <Bolt />
                                      </div>
                                    </Tooltip>
                                  </div>
                                  <div className='main'>
                                    <Typography variant='body1' color='text.primary'>Principal</Typography>
                                    <Tooltip title={store.main}>
                                      <div className={`sensor-status ${store.main < AC ? 'red' : 'green'}`}>
                                        <Bolt />
                                      </div>
                                    </Tooltip>
                                  </div>
                                  <div className='jockey'>
                                    <Typography variant='body1' color='text.primary'>Jockey</Typography>
                                    <Tooltip title={store.jockey}>
                                      <div className={`sensor-status ${store.jockey < AC ? 'yellow' : 'green'}`}>
                                        <Bolt />
                                      </div>
                                    </Tooltip>
                                  </div>
                                </Box>
                              </TableCell>
                            )}
                            {DC_SENSOR_ENABLE && (
                              <TableCell>
                                <Box className='alarm-status'>
                                  <div className='power-alarm'>
                                    <Typography variant='body1' color='text.primary'>Central</Typography>
                                    {/* <Tooltip title={store?.alert ? 'Ligado' : 'Desligado'}> */}
                                    <div className={`sensor-status ${store?.dc1 ? 'red' : 'green'}`}>
                                      <Bolt />
                                    </div>
                                    {/* </Tooltip> */}
                                  </div>
                                  <div className='alert'>
                                    <Typography variant='body1' color='text.primary'>Sirene</Typography>
                                    {/* <Tooltip title={store?.alarm ? 'Ligado' : 'Desligado'}> */}
                                    <div className={`sensor-status ${store?.dc2 ? 'green' : 'red'}`}>
                                      <Bolt />
                                    </div>
                                    {/* </Tooltip> */}
                                  </div>
                                </Box>
                              </TableCell>
                            )}
                            {ELECTRIC_GENERATOR_SENSOR_ENABLE && (
                              <TableCell align='right'>
                                <Box className='electric-generator-status' sx={{ width: '150px' }}>
                                  <div className='status'>
                                    <Typography variant='body1' color='text.primary'>Status</Typography>
                                    <Switch
                                      variant='generator'
                                      checked={store?.genStatus}
                                      onChange={e => toggleGenerator(e, store)}
                                      disabled={genStatusSubmitting}
                                    />
                                  </div>
                                  <div className='phase'>
                                    <div className='r'>
                                      <Typography variant='body1' color='text.primary'>R</Typography>
                                      <Tooltip title={store?.r ? 'Ligado' : 'Desligado'}>
                                        <div className={`sensor-status ${store?.r ? 'green' : 'red'}`}>
                                          <Bolt />
                                        </div>
                                        {/* <Typography variant='body2' color='text.primary'>{`${(store?.r / 4095) * R}v`}</Typography> */}
                                      </Tooltip>
                                    </div>
                                    <div className='s'>
                                      <Typography variant='body1' color='text.primary'>S</Typography>
                                      <Tooltip title={store?.s ? 'Ligado' : 'Desligado'}>
                                        <div className={`sensor-status ${store?.s ? 'green' : 'red'}`}>
                                          <Bolt />
                                        </div>
                                        {/* <Typography variant='body2' color='text.primary'>{`${(store?.s / 4095) * S}v`}</Typography> */}
                                      </Tooltip>
                                    </div>
                                    <div className='t'>
                                      <Typography variant='body1' color='text.primary'>T</Typography>
                                      <Tooltip title={store?.t ? 'Ligado' : 'Desligado'}>
                                        <div className={`sensor-status ${store?.t ? 'green' : 'red'}`}>
                                          <Bolt />
                                        </div>
                                        {/* <Typography variant='body2' color='text.primary'>{`${(store?.t / 4095) * T}v`}</Typography> */}
                                      </Tooltip>
                                    </div>
                                  </div>
                                  <div className='fuel-level'>
                                    <Typography variant='body1' color='text.primary'>Combustível</Typography>
                                    <Tooltip title={`${parseInt((store?.fuel / 4095) * 100)}%`}>
                                      {/*
                                valor < 26 = vermelho
                                valor > 25 && valor < 50 = amarelo
                                valor > 50 = verde
                                */}
                                      <LinearProgress
                                        variant='determinate'
                                        fuel
                                        value={(store?.fuel / 4095) * 100}
                                      />
                                    </Tooltip>
                                  </div>
                                </Box>
                              </TableCell>
                            )}
                            <TableCell align='right'>
                              <Box className='actions'>
                                <IconButton variant='text' className='action' onClick={() => navigate(`${PATHS.STORE}/${store.id}`)}>
                                  <Timeline color='primary' />
                                </IconButton>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  )
            )}
      </Container>
    </>
  )
}
