export const DEFAULT_VALUES = Object.freeze({
  THRESHOLD: {
    AC: 3000,
    DC: 0
  },
  PHASES: {
    R: 0,
    S: 0,
    T: 0
  }
})
