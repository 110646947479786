import React from 'react'
import { ReactComponent as LogotypeBibe } from '../../assets/images/bibe_logotype.svg'
import { Alert, Box, Button, Container, IconButton, Snackbar, TextField, Typography, useTheme } from '@mui/material'
import { useFormik } from 'formik'
import { signIn, signInWithGoogle } from 'src/firebase'
import { useLocation, useNavigate } from 'react-router-dom'
import { Google } from '@mui/icons-material'
import './style.scss'
import { useSelector } from 'react-redux'
import { userSelector } from 'src/redux/features/user/adapters'
import { isAdmin } from 'src/Utils/Users'

function SignIn () {
  const user = useSelector(userSelector)
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()
  const [error, setError] = React.useState('')
  const [logo, setLogo] = React.useState()

  const handleSubmit = React.useCallback(async values => {
    await signIn(values).catch(err => {
      console.error(err)
      return err
    })
    return navigate('/')
  }, [navigate])

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: handleSubmit
  })

  const handleEmailChange = React.useCallback(event => {
    const { value } = event.target
    formik.setFieldValue('email', value)
  }, [formik])

  const handlePasswordChange = React.useCallback(event => {
    const { value } = event.target
    formik.setFieldValue('password', value)
  }, [formik])

  const handleSignInWithGoogle = React.useCallback(
    async () => {
      try {
        await signInWithGoogle()
        return navigate('/')
      } catch (err) {
        const error = JSON.parse(err.message)
        return setError(error?.data?.message)
      }
    }
    , [navigate])

  const handleClose = React.useCallback((_, reason) => {
    window.history.replaceState({ }, '')
    if (reason === 'clickaway') {
      return
    }
    setError('')
  }, [])

  React.useEffect(() => {
    const logoPath = {
      bibe: require('@images/bibe_logo.svg'),
      paguemenos: require('@images/paguemenos_logo.png'),
      boa: require('@images/boa_logo.png')
    }[theme.tenant]
    setLogo(prev => logoPath && logoPath.default ? logoPath?.default : logoPath || prev)
  }, [theme.tenant])

  React.useEffect(() => {
    if (user?.uid && user?.active) {
      return navigate('/')
    }
    if (location.state?.authorizationError) {
      setError('Houve um problema!')
    }
  },
  [
    location.state?.authorizationError,
    navigate,
    user?.active,
    user?.uid
  ])

  return (
    <>
      <Container component='main' className='sign-in' maxWidth='xs'>
        <Box className='header'>
          <LogotypeBibe
            className='logo'
            fill={theme.palette.primary.contrastText}
          />
        </Box>
        <Box
          className='content'
          sx={{
            backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.primary.dark
              : theme.palette.white.main
          }}
        >
          {!isAdmin() && logo && (
            <img
              className='tenant-logo'
              src={logo}
              alt='logo'
            />
          )}
          <Box
            component='form'
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <TextField
              value={formik.values.email}
              onChange={handleEmailChange}
              margin='normal'
              required
              fullWidth
              label='Email'
              name='email'
              autoComplete='email'
              autoFocus
            />
            <TextField
              value={formik.values.password}
              onChange={handlePasswordChange}
              margin='normal'
              required
              fullWidth
              name='password'
              label='Senha'
              type='password'
              autoComplete='current-password'
            />
            <Button
              type='submit'
              fullWidth
              size='large'
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
            >
              Entrar
            </Button>
          </Box>
          <Typography variant='body1' color={theme.palette.mode === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary} className='sign-in__or'>Ou</Typography>
          <Box className='sso'>
            <Typography variant='body1' color={theme.palette.mode === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary} className='sso__google'>Entrar com </Typography>
            <IconButton onClick={handleSignInWithGoogle} sx={{ p: 0 }}>
              <Google />
            </IconButton>
          </Box>
        </Box>
      </Container>
      <Snackbar
        open={!!error}
        autoHideDuration={10000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity='error'
          variant='filled'
          sx={{ width: '100%' }}
        >
          Não foi possível realizar o login!
        </Alert>
      </Snackbar>
    </>
  )
}

export default SignIn
