import React from 'react'
import { PageHeader } from 'src/components/Header/Page'
import { AppBar, Button, Grid, Paper, Tab, Tabs, TextField, Toolbar, Typography } from '@mui/material'
import { Search } from '@mui/icons-material'
import { HttpClient } from 'src/Utils/HttpClient'
import { PermissionsContent } from 'src/components/Permissions'
import { RolesContent } from 'src/components/Roles'

export function Users () {
  const [users, setUsers] = React.useState([])
  const [tabValue, setTabValue] = React.useState(0)
  // const [openPermissionsModal, setOpenPermissionsModal] = React.useState(false)
  const fetchUsers = React.useCallback(
    async () => {
      const response = await HttpClient.get('/users')
      setUsers(response.data)
    },
    []
  )
  /*
  const openModal = React.useCallback(
    () => {
      setOpenPermissionsModal(true)
    },
    []
  )
  const closeModal = React.useCallback(
    () => {
      setOpenPermissionsModal(false)
    },
    []
  )
  */
  const handleChange = React.useCallback(
    (event, newValue) => {
      setTabValue(newValue)
    },
    []
  )

  React.useEffect(
    () => {
      fetchUsers()
    },
    [fetchUsers]
  )

  return (
    <>
      <PageHeader title='Usuários' />
      <Tabs value={tabValue} onChange={handleChange} aria-label='basic tabs example'>
        <Tab label='Usuários' />
        <Tab label='Papeis' />
        <Tab label='Permissões' />
      </Tabs>
      {tabValue === 0 && (
        <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
          <AppBar
            position='static'
            color='default'
            elevation={0}
            sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
          >
            <Toolbar>
              <Grid container spacing={2} alignItems='center'>
                <Grid item>
                  <Search color='inherit' sx={{ display: 'block' }} />
                </Grid>
                <Grid item xs>
                  <TextField
                    fullWidth
                    placeholder='Busque buscar por nome'
                    InputProps={{
                      disableUnderline: true,
                      sx: { fontSize: 'default' }
                    }}
                    variant='standard'
                  />
                </Grid>
                {/* <Grid item>
                  <Button variant='contained' sx={{ mr: 1 }} onClick={openModal}>
                    Criar usuário
                  </Button>
                  <Tooltip title='Atualizar'>
                    <IconButton onClick={fetchUsers}>
                      <Refresh color='inherit' sx={{ display: 'block' }} />
                    </IconButton>
                  </Tooltip>
                </Grid> */}
              </Grid>
            </Toolbar>
          </AppBar>
          {users.length < 1
            ? (
              <Typography sx={{ my: 5, mx: 2 }} color='text.secondary' align='center'>
                Nenhum usuário encontrado
              </Typography>
              )
            : (
              <Grid container spacing={2} alignItems='center' sx={{ p: 2 }}>
                {users.map(user => (
                  <Grid item xs={12} md={6} lg={4} key={user.id}>
                    <Paper
                      sx={{
                        p: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Grid container spacing={2} alignItems='center'>
                        <Grid item>
                          <Typography variant='subtitle1' sx={{ display: 'inline-block' }}>
                            {user.name}
                          </Typography>
                          <Typography variant='body2' sx={{ display: 'inline-block', ml: 2 }}>
                            {new Date(user.createdAt).toLocaleDateString('pt-BR')}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Button variant='outlined'>Edit</Button>
                      </Grid>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
              )}
        </Paper>
      )}
      {tabValue === 1 && (
        <RolesContent />
      )}
      {tabValue === 2 && (
        <PermissionsContent />
      )}
    </>
  )
}
