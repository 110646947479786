import React from 'react'
import PropTypes from 'prop-types'
import {
  Menu as MenuIcon//,
  // Notifications as NotificationsIcon,
  // Help as HelpIcon
} from '@mui/icons-material'
import {
  Menu,
  MenuItem,
  Typography,
  AppBar,
  Avatar,
  Grid,
  IconButton,
  Toolbar
} from '@mui/material'
import './styles.scss'
import { useSelector } from 'react-redux'
import { userSelector } from 'src/redux/features/user/adapters'
import { isAdmin } from 'src/Utils/Users'
import { signOut } from 'src/firebase'

function Header (props) {
  const { onDrawerToggle } = props
  const user = useSelector(userSelector)
  const [userMenu, setUserMenu] = React.useState(null)

  const handleOpenMenu = React.useCallback(
    event => {
      setUserMenu(event.currentTarget)
    },
    []
  )

  const handleCloseMenu = React.useCallback(
    event => {
      if (event.currentTarget.textContent === 'Sair') {
        signOut()
      }
      setUserMenu(null)
    },
    []
  )

  return (
    <>
      <AppBar color='white' position='sticky' elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems='center'>
            {isAdmin(user?.uid) && (
              <Grid sx={{ display: { sm: 'none', xs: 'block' } }} item>
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  onClick={onDrawerToggle}
                  edge='start'
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            )}
            <Grid item xs />
            {/* <Grid item>
              <Tooltip title='Alerts • No alerts'>
                <IconButton color='inherit'>
                  <NotificationsIcon />
                </IconButton>
              </Tooltip>
            </Grid> */}
            <Grid item>
              <IconButton onClick={handleOpenMenu} color='inherit' sx={{ p: 0.5 }}>
                <Avatar alt={user?.displayName} src={user?.photoURL}>{!user?.photoURL ? user?.displayName?.[0] : null}</Avatar>
              </IconButton>
              <Menu
                anchorEl={userMenu}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                open={Boolean(userMenu)}
                onClose={handleCloseMenu}
              >
                <MenuItem onClick={handleCloseMenu}>
                  <Typography textAlign='center'>Sair</Typography>
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  )
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired
}

export default Header
