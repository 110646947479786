import React from 'react'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import { Outlet } from 'react-router-dom'
import './style.scss'
import Navigator from '../Navigator'
import { Copyright } from '../Copyright'
import Header from '../Header'
import { useSelector } from 'react-redux'
import { userSelector } from 'src/redux/features/user/adapters'
import { isAdmin } from 'src/Utils/Users'

export const MainBase = () => {
  const user = useSelector(userSelector)
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const drawerWidth = 256

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  return (
    <>
      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        {isAdmin(user?.uid) && (
          <Box
            component='nav'
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          >
            {isSmUp
              ? null
              : (
                <Navigator
                  PaperProps={{ style: { width: drawerWidth } }}
                  variant='temporary'
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                />
                )}

            <Navigator
              PaperProps={{
                style: {
                  width: drawerWidth
                }
              }}
              variant='permanent'
              sx={{
                display: { sm: 'flex', xs: 'none' },
                flexDirection: 'column'
              }}
            />
          </Box>
        )}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Header onDrawerToggle={handleDrawerToggle} />
          <Box
            component='main'
            className='base-container'
            sx={{
              flex: 1,
              py: 6,
              px: { xs: 0, md: 4 },
              bgcolor: theme.palette.background.default
            }}
          >
            <Outlet />
          </Box>
          <Box component='footer' sx={{ p: 2, bgcolor: theme.palette.background.default }}>
            <Copyright />
          </Box>
        </Box>
      </Box>
    </>
  )
}
