import * as React from 'react'
import './style.scss'
import { Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Switch, useTheme } from '@mui/material'
import { ReactComponent as LogotypeBibe } from '../../assets/images/bibe_logotype.svg'
import { ColorModeContext } from 'src/providers/Theme'
import { Brightness7, People, Storefront, ShoppingCart, Dashboard } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { PATHS } from 'src/Utils/Router'

const categories = [
  { id: 'Painel', path: PATHS.HOME, icon: <Dashboard />, role: 'editor' },
  { id: 'Usuários', path: `/${PATHS.USERS}`, icon: <People />, role: 'admin' },
  { id: 'Lojas', path: `/${PATHS.STORES}`, icon: <ShoppingCart />, role: 'admin' },
  { id: 'Clientes', path: `/${PATHS.CUSTOMERS}`, icon: <Storefront />, role: 'admin' }
]

export default function Navigator (props) {
  const theme = useTheme()
  const { toggleColorMode, color } = React.useContext(ColorModeContext)
  const { ...other } = props

  const handleColorMode = React.useCallback(
    () => {
      toggleColorMode()
    },
    [toggleColorMode]
  )

  return (
    <Drawer {...other}>
      <List className='menu'>
        <ListItem className='menu-item__logo'>
          <LogotypeBibe fill={color === 'light' ? theme.palette.purple.main : theme.palette.green.main} />
          <Divider />
        </ListItem>
        {categories.map(({ id, icon, path, active }, index) => (
          <React.Fragment key={index}>
            <ListItem component={Link} to={path} color='text.primary' className='menu-item' sx={{ px: 0 }} key={id}>
              <ListItemButton selected={active}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText>{id}</ListItemText>
              </ListItemButton>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
        <Divider sx={{ mt: 'auto' }} />
        <ListItem>
          <ListItemIcon>
            <Brightness7 />
          </ListItemIcon>
          <ListItemText primary='Tema' />
          <Switch
            edge='end'
            variant='theme'
            onChange={handleColorMode}
            checked={color === 'light'}
            inputProps={{
              'aria-labelledby': 'switch-list-label-theme'
            }}
          />
        </ListItem>
      </List>
    </Drawer>
  )
}
