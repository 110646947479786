import React from 'react'
import { remoteConfig } from '../../firebase'
import { fetchAndActivate, getAll } from 'firebase/remote-config'
const FlagsContext = React.createContext({})

// Only for development
remoteConfig.settings = {
  minimumFetchIntervalMillis: 30000
}

const sanitizeValues = value => {
  switch (value) {
    case 'true':
      return true
    case 'false':
      return false
    case value.startsWith('"{'):
      return JSON.parse(value)
    default:
      return value
  }
}

export const FlagsProvider = ({ defaults, children }) => {
  const [flags, setFlags] = React.useState(defaults)
  React.useEffect(() => {
    remoteConfig.defaultConfig = defaults
    fetchAndActivate(remoteConfig)
      .then(() => getAll(remoteConfig))
      .then(remoteFlags => {
        const newFlags = {}
        for (const prop in remoteFlags) {
          const { _value } = remoteFlags[prop]
          if (/^[\],:{}\s]*$/.test(_value
            .replace(/\\["\\/bfnrtu]/g, '@')
            .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g, ']')
            .replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
            newFlags[prop] = JSON.parse(_value)
          } else {
            newFlags[prop] = sanitizeValues(_value)
          }
        }
        return setFlags(prev => ({ ...prev, ...newFlags }))
      })
      .catch(error => console.error(error))
  }, [defaults])
  return (
    <FlagsContext.Provider value={flags}>
      {children}
    </FlagsContext.Provider>
  )
}

export const useFlags = () => {
  const context = React.useContext(FlagsContext)
  return context
}
