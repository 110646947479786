import { createAsyncThunk } from '@reduxjs/toolkit'
import { HttpClient } from 'src/Utils/HttpClient'

export const fetchUserData = createAsyncThunk(
  'user/fetchUserData',
  async (_, thunkAPI) => {
    try {
      const response = await HttpClient.get('/users/me')
      if (!response.data.active) {
        throw new Error('User is not active')
      }
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message })
    }
  }
)

export const fetchUserRoles = createAsyncThunk(
  'user/fetchUserRoles',
  async (userId, thunkAPI) => {
    try {
      const response = await HttpClient.get(`/users/${userId}/roles`)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message })
    }
  }
)
