// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getRemoteConfig } from 'firebase/remote-config'
import { getAuth, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup, signOut as _signOut } from 'firebase/auth'
import { store } from './redux/store'
import { unsetUser } from './redux/features/user/slice'

const config = {
  apiKey: 'AIzaSyAk1-ylGgOSaLgMCLZ_baZKMDMiEYpC0Pg',
  authDomain: 'bibe-tech.firebaseapp.com',
  databaseURL: 'https://bibe-tech-default-rtdb.firebaseio.com',
  projectId: 'bibe-tech',
  storageBucket: 'bibe-tech.appspot.com',
  messagingSenderId: '423489668995',
  appId: '1:423489668995:web:74d5998259b810e14d18b6',
  measurementId: 'G-2XEGF5NQ11'
}

// Initialize Firebase
export const app = initializeApp(config)
export const analytics = getAnalytics(app)
export const auth = getAuth(app)
const provider = new GoogleAuthProvider()
export const remoteConfig = getRemoteConfig(app)

export async function signInWithGoogle () {
  try {
    await signInWithPopup(auth, provider)
  } catch (error) {
    throw GoogleAuthProvider.credentialFromError(error)
  }
}

export async function signIn ({ email, password }) {
  try {
    return await signInWithEmailAndPassword(auth, email, password)
  } catch (error) {
    return error
  }
}

export const signOut = () => {
  store.dispatch(unsetUser())
  return _signOut(auth)
}
