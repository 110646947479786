import { ShowChart, BarChart as BarChartIcon } from '@mui/icons-material'
import { Box, Container, Grid, Paper, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { LineChart, BarChart } from '@mui/x-charts'
import { collection, getFirestore, onSnapshot, query, where } from 'firebase/firestore'
import { DateTime } from 'luxon'
import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { PageHeader } from 'src/components/Header/Page'
import { app } from 'src/firebase'
import { userSelector } from 'src/redux/features/user/adapters'
import './styles.scss'

export const Store = () => {
  const user = useSelector(userSelector)
  const { storeID } = useParams()
  const [rawResult, setRawResult] = React.useState([])
  const [storeHistory, setStoreHistory] = React.useState([])
  const [sensors, setSensors] = React.useState([
    { label: 'Rede', dataKey: 'power', color: '#02B2AF', type: 1 },
    { label: 'Emergência', dataKey: 'emergency', color: '#2E96FF', type: 1 },
    { label: 'Principal', dataKey: 'main', color: '#B800D8', type: 1 },
    { label: 'Jockey', dataKey: 'jockey', color: '#60009B', type: 1 }
  ])

  const getStoreName = React.useCallback(() => {
    const storeName = user?.customers?.[0]?.stores?.find(store => store.id === storeID)?.name
    return `Loja ${storeName}`
  }, [storeID, user?.customers])

  // const THRESHOLD = 3000

  // const sensorsDTO = (value) => ({
  //   power: value.power < THRESHOLD ? 0 : 1,
  //   emergency: value.emergency > THRESHOLD ? 0 : 1,
  //   main: value.main > THRESHOLD ? 0 : 1,
  //   jockey: value.jockey > THRESHOLD ? 0 : 1
  // })

  const filter = React.useCallback((values = []) => values
    .filter(value => value.timestamp && value.power && value.emergency && value.main && value.jockey)
    .map(value => {
      value.timestamp = DateTime.fromISO(value.timestamp).toJSDate()
      // value = { ...value, ...sensorsDTO(value) }
      // const { r, s, t, fuel, store, customerId, ...newValue } = value
      return value
    })
    .sort((a, b) => a.timestamp > b.timestamp ? -1 : a.timestamp < b.timestamp ? 0 : 1)
  , [])

  const toggleChartType = React.useCallback((index) => {
    const newSensors = sensors.map((sensor, i) => {
      if (i === index) {
        sensor.type = sensor.type === 1 ? 0 : 1
      }
      return sensor
    })
    setSensors(newSensors)
  }
  , [sensors])

  React.useEffect(() => {
    const db = getFirestore(app)
    const sensorsRef = collection(db, 'sensors')
    const q = query(sensorsRef, where('store', '==', storeID))
    onSnapshot(q, result => {
      const r = result?.docs?.map(doc => doc.data())
      setRawResult(r)
    })
  }, [storeID])

  React.useEffect(() => {
    if (rawResult.length < 1) return
    const y = filter(rawResult)
    setStoreHistory(y)
  }, [filter, rawResult])

  return (
    <>
      <PageHeader title={getStoreName()} />
      <Container component='main' className='store-container' maxWidth='xl'>
        <Grid container spacing={3}>
          {sensors.map((sensor, index) => (
            <Grid item sm={12} md={6} xl={3} key={sensor.label}>
              <Box component={Paper} p={2}>
                {storeHistory.length > 0 && (
                  sensor.type === 1
                    ? (
                      <LineChart
                        className='line-chart'
                        dataset={storeHistory}
                        xAxis={[
                          {
                            dataKey: 'timestamp',
                            scaleType: 'time',
                            tickInterval: 0.01,
                            tickLabelInterval: 0.01
                          }
                        ]}
                        width={400}
                        height={300}
                        series={[
                          {
                            curve: 'linear',
                            showMark: false,
                            dataKey: sensor.dataKey,
                            label: sensor.label,
                            color: sensor.color
                          }
                        ]}
                      />
                      )
                    : (
                      <BarChart
                        className='bar-chart'
                        dataset={storeHistory}
                        xAxis={[
                          {
                            dataKey: 'timestamp',
                            scaleType: 'band',
                            tickInterval: 0.01
                          }
                        ]}
                        width={400}
                        height={300}
                        series={[
                          {
                            dataKey: sensor.dataKey,
                            label: sensor.label,
                            color: sensor.color
                          }
                        ]}
                      />
                      )
                )}
                <Box className='chart-type'>
                  <ToggleButtonGroup
                    value={sensor.type}
                    exclusive
                    onChange={() => toggleChartType(index)}
                  >
                    <ToggleButton value={1}>Linha <ShowChart /></ToggleButton>
                    <ToggleButton value={0}>Barras <BarChartIcon /></ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  )
}
