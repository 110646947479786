import React from 'react'
import { Router } from './components/Routes'
import { ColorModeProvider } from './providers/Theme'
import { FlagsProvider } from './providers/Flags'
import { DEFAULT_VALUES } from './constants'
import { useDispatch } from 'react-redux'
import { onAuthStateChanged } from 'firebase/auth'
import { auth, signOut } from './firebase'
import { fetchUserData, fetchUserRoles } from './redux/features/user/thunk'
import { setUser } from './redux/features/user/slice'

const App = () => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(setUser({ loading: true }))
    const unsubscribe = onAuthStateChanged(auth, async user => {
      if (user) {
        const { uid, email, displayName, photoURL } = user
        const userResponse = await dispatch(fetchUserData()).unwrap()
        dispatch(fetchUserRoles(userResponse.id))
        dispatch(setUser({ ...userResponse, uid, email, displayName, photoURL }))
      } else {
        return signOut()
      }
    })
    return () => unsubscribe()
  }, [dispatch])

  return (
    <ColorModeProvider>
      <FlagsProvider defaults={DEFAULT_VALUES}>
        <Router />
      </FlagsProvider>
    </ColorModeProvider>
  )
}

export default App
