export const PATHS = Object.freeze({
  HOME: '/',
  CUSTOMERS: 'clientes',
  STORES: 'lojas',
  STORE: 'loja',
  ABOUT: 'about',
  USERS: 'usuarios',
  CONTACT: 'contact',
  SIGN_IN: 'sign-in',
  ADMIN: 'admin',
  PROFILE: 'profile',
  TERMS: 'terms',
  PRIVACY: 'privacy',
  NOT_FOUND: '*'
})
