import React from 'react'
import { AppBar, Grid, Toolbar, Typography } from '@mui/material'

export function PageHeader ({ title, children }) {
  return (
    <AppBar
      color='white'
      position='static'
      elevation={0}
      sx={{ zIndex: 0 }}
    >
      <Toolbar>
        <Grid container alignItems='center' spacing={1}>
          <Grid item xs>
            <Typography variant='h5' component='h1'>
              {title}
            </Typography>
          </Grid>
          {/* <Grid item>
              <Tooltip title='Help'>
                <IconButton color='inherit'>
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid> */}
          {children}
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
