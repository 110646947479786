import React from 'react'
import { Typography, Button, Box } from '@mui/material'
import { Link } from 'react-router-dom'
import { ReactComponent as LogotypeBibe } from '../../assets/images/bibe_logotype.svg'
import './styles.scss'

export function NotFound () {
  return (
    <Box className='page-not-found'>
      <LogotypeBibe className='page-not-found__logo' />
      <Typography variant='h1' className='page-not-found__title'>
        404
      </Typography>
      <Typography variant='h2' className='page-not-found__subtitle'>
        Página não encontrada
      </Typography>
      <Typography variant='h6' className='page-not-found__message'>
        A página que você está procurando não pode ser encontrada.
      </Typography>
      <Button
        className='page-not-found__button'
        variant='contained'
        size='large'
        component={Link}
        to='/'
      >
        Voltar para o inicio
      </Button>
    </Box>
  )
}
