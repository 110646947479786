import { Button, Checkbox, Container, FormControl, FormControlLabel, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React from 'react'
import { HttpClient } from 'src/Utils/HttpClient'
import { PageHeader } from 'src/components/Header/Page'
import './styles.scss'
import { Formik } from 'formik'
import { Add, Delete, Edit } from '@mui/icons-material'

export function Customers () {
  const [customers, setCustomers] = React.useState([])
  const [openModal, setOpenModal] = React.useState(false)
  const fetchCustomers = React.useCallback(
    async () => {
      const response = await HttpClient.get('/customers')
      setCustomers(response.data)
    },
    []
  )

  React.useEffect(
    () => {
      fetchCustomers()
    },
    [fetchCustomers]
  )
  return (
    <>
      <PageHeader title='Mercados' />
      <Container component='main' className='customers-container' maxWidth='xl'>
        <Button
          component='label' variant='contained' startIcon={<Add />}
          className='add-new-customer' aria-label='add' onClick={() => setOpenModal(true)}
        >
          Novo cliente
        </Button>
        {customers.length === 0 && (
          <Typography>Nenhum cliente cadastrado</Typography>
        )}
        <TableContainer component={Paper} className='customers-list'>
          <Table sx={{ minWidth: 700 }} aria-label='customized table'>
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Ativo</TableCell>
                <TableCell align='right'>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.map((customer) => (
                <TableRow key={customer.id}>
                  <TableCell>
                    {customer.name}
                  </TableCell>
                  <TableCell>{customer.active ? 'Sim' : 'Não'}</TableCell>
                  <TableCell align='right'>
                    <Edit />
                    <Delete />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Modal open={openModal} onClose={() => setOpenModal(false)} className='customers-modal'>
        <Paper className='create-customer'>
          <Typography variant='h3' className='create-customer__title'>Novo cliente</Typography>
          <Formik
            initialValues={{
              name: '',
              active: true
            }}
            onSubmit={async (values) => {
              await HttpClient.post('/customers', values)
              setOpenModal(false)
              fetchCustomers()
            }}
          >
            {({ values, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit} className='customer-form'>
                <FormControl fullWidth>
                  <TextField label='Nome' value={values.name} onChange={handleChange} />
                </FormControl>
                <FormControlLabel control={<Checkbox name='active' checked={values.active} onChange={handleChange} />} label='Ativo' />
                <Button variant='contained' size='large' type='submit' className='customer-form__button'>Cadastrar</Button>
              </form>
            )}
          </Formik>
        </Paper>
      </Modal>
    </>
  )
}
