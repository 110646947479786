import React from 'react'
import { Button, Checkbox, FormControlLabel, FormGroup, Grid, Paper, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { HttpClient } from 'src/Utils/HttpClient'
import './styles.scss'

export const PermissionsContent = React.memo(() => {
  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      resource: '',
      action: [
        { name: 'list', label: 'Listar', checked: false },
        { name: 'create', label: 'Criar', checked: false },
        { name: 'update', label: 'Editar', checked: false },
        { name: 'delete', label: 'Excluir', checked: false }
      ]
    }
  })

  const [permissions, setPermissions] = React.useState([])

  const fetchPermissions = React.useCallback(
    async () => {
      const response = await HttpClient.get('/permissions')
      setPermissions(response.data)
    },
    []
  )
  const deletePermission = React.useCallback(
    async id => {
      await HttpClient.delete(`/permissions/${id}`)
      fetchPermissions()
    },
    [fetchPermissions]
  )

  const handleChange = React.useCallback(
    function (event) {
      const { name, value } = event.target
      formik.setFieldValue(name, value)
    },
    [formik]
  )

  const handleActionChange = React.useCallback(
    function (event) {
      const { name, checked } = event.target
      const actions = formik.values.action.map(action => {
        if (action.name === name) {
          return { ...action, checked }
        }
        return action
      })
      formik.setFieldValue('action', actions)
    },
    [formik]
  )

  const handleSubmit = React.useCallback(
    async () => {
      const actionValues = formik.values.action
        .filter(action => action.checked)
        .map(action => action.name)
      const action = JSON.stringify(actionValues)
      const values = { ...formik.values, action }
      debugger
      const response = await HttpClient.post('/permissions', values)
      setPermissions(prev => [...prev, response?.data])
      formik.resetForm()
    },
    [formik]
  )

  React.useEffect(
    () => {
      fetchPermissions()
    },
    [fetchPermissions]
  )
  return (
    <Paper className='permissions-modal__content'>
      <Typography variant='h6' sx={{ p: 2 }}>
        Permissões
      </Typography>
      <Grid container spacing={2} alignItems='center' sx={{ p: 2 }}>
        {permissions.map(permission => (
          <Grid item xs={12} md={6} lg={4} key={permission.id}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Grid container spacing={2} alignItems='center'>
                <Grid item>
                  <Typography variant='subtitle1' sx={{ display: 'inline-block' }}>
                    {permission.name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Button variant='outlined' onClick={() => deletePermission(permission.id)}>Excluir</Button>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2} alignItems='center' sx={{ p: 2 }}>
        <Grid item>
          <TextField
            label='Nome'
            variant='standard'
            value={formik.values.name}
            onChange={handleChange}
            name='name'
            id='name'
          />
          <TextField
            label='Descrição'
            variant='standard'
            value={formik.values.description}
            onChange={handleChange}
            name='description'
            id='description'
          />
          <TextField
            label='Recurso'
            variant='standard'
            value={formik.values.resource}
            onChange={handleChange}
            name='resource'
            id='resource'
          />
          <FormGroup>
            {
              formik.values?.action?.map(action => (
                <FormControlLabel
                  key={action.name}
                  name={action.name}
                  onChange={handleActionChange}
                  control={
                    <Checkbox
                      checked={action.checked}
                    />
                  }
                  label={action.label}
                />
              ))
            }
          </FormGroup>
          <Button variant='outlined' onClick={handleSubmit}>Criar</Button>
        </Grid>
      </Grid>
    </Paper>
  )
})
