import { ThemeProvider, createTheme } from '@mui/material/styles'
import React from 'react'

const overrides = theme => ({
  MuiTableHead: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.mode === 'dark'
          ? '#fff'
          : theme.palette.gray.main,
        '& th.MuiTableCell-root': {
          color: theme.palette.mode === 'dark'
            ? theme.palette.gray.main
            : '#fff'
        }
      }
    }
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        ...(ownerState.orientation === 'vertical' && {
          borderRadius: 50,
          width: 50,
          height: '100px',
          '& .MuiLinearProgress-bar': {
            transform: `translateY(${ownerState.value}%)!important`
          }
        }),
        ...(ownerState.fuel === true && {
          borderRadius: 50,
          height: 15,
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          '& .MuiLinearProgress-bar': {
            borderRadius: 50,
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2) inset',
            backgroundColor:
              ownerState.value < 26
                ? 'red'
                : ownerState.value > 25 && ownerState.value < 51
                  ? 'orange'
                  : ownerState.value > 50 && ownerState.value < 76
                    ? 'yellow'
                    : 'lime'
          }
        })
      })
    }
  },
  MuiSwitch: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        ...(ownerState.variant === 'generator' && {
          '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
              '& .MuiSwitch-thumb': {
                backgroundColor: 'lime',
                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.7), 0px 3px 1px rgba(255, 255, 255, 0.2) inset'
              },
              '& + .MuiSwitch-track': {
                backgroundColor: 'lime',
                boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2) inset'
              }
            }
          }
        })
      })
    },
    variants: [
      {
        props: { variant: 'theme' },
        style: {
          width: 62,
          height: 34,
          padding: 7,
          '& .MuiSwitch-switchBase': {
            margin: 1,
            padding: 0,
            transform: 'translateX(6px)',
            '&.Mui-checked': {
              color: '#fff',
              transform: 'translateX(22px)',
              '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff'
              )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`
              },
              '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark'
                  ? '#8796A5'
                  : '#aab4be'
              }
            }
          },
          '& .MuiSwitch-thumb': {
            backgroundColor: theme.palette.mode === 'dark'
              ? theme.palette.green.main
              : theme.palette.purple.main,
            width: 32,
            height: 32,
            '&:before': {
              content: "''",
              position: 'absolute',
              width: '100%',
              height: '100%',
              left: 0,
              top: 0,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent()}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`
            }
          },
          '& .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            borderRadius: 20 / 2
          }
        }
      }
    ]
  }
})
const defaultColors = {
  purple: {
    main: '#662d91',
    light: '#8970c1',
    dark: '#451a6f',
    contrastText: '#fff'
  },
  green: {
    main: '#ceff00',
    light: '#e5ffc5',
    dark: '#97b900',
    contrastText: '#000'
  },
  gray: {
    main: '#333333',
    light: '#666666',
    dark: '#000000',
    contrastText: '#ffffff'
  }
}
const mixins = {
  toolbar: {
    minHeight: 48
  }
}
const shape = {
  borderRadius: 8
}
const typography = {
  h5: {
    fontWeight: 500,
    fontSize: 26,
    letterSpacing: 0.5
  }
}

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: defaultColors.gray,
    secondary: defaultColors.purple,
    white: {
      main: '#fff'
    },
    // background: {
    //   default: '#fff',
    //   paper: '#fff'
    // },
    // text: {
    //   primary: '#333'
    // },
    ...defaultColors
  },
  mixins,
  shape,
  typography
})

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: defaultColors.gray,
    secondary: defaultColors.purple,
    // background: {
    //   default: '#333333',
    //   paper: '#333333'
    // },
    // text: {
    //   primary: '#fff'
    // },
    ...defaultColors
  },
  mixins,
  shape,
  typography
})

export const ColorModeContext = React.createContext()

export function ColorModeProvider ({ children }) {
  const [mode, setMode] = React.useState('light')
  const [tenant, setTenant] = React.useState('')
  React.useEffect(
    () => {
      const _tenant = window.location.hostname.split('.')[0]
      window.localStorage.setItem('tenant', _tenant)
      const mode = window.localStorage.getItem('color-mode') || 'light'
      const html = document.getElementsByTagName('html')
      html[0].classList.add(mode)
      setMode(mode)
      setTenant(_tenant)
    },
    []
  )
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode(prevMode => {
          const value = prevMode === 'light' ? 'dark' : 'light'
          window.localStorage.setItem('color-mode', value)
          const html = document.getElementsByTagName('html')
          html[0].classList.replace(prevMode, value)
          return value
        })
      },
      color: mode
    }),
    [mode]
  )

  const theme = React.useMemo(
    () => ({
      light: {
        ...lightTheme,
        components: overrides(lightTheme),
        tenant
      },
      dark: {
        ...darkTheme,
        components: overrides(darkTheme),
        tenant
      }
    }[mode]),
    [mode, tenant]
  )

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}
