import axios from 'axios'
import { signOut } from 'firebase/auth'
import { auth } from 'src/firebase'

export const HttpClient = axios.create({
  baseURL: process.env.REACT_APP_API_HOST || 'http://localhost:5001/',
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 10000
})

// Add a request interceptor
HttpClient.interceptors.request.use(function (config) {
  // Do something before request is sent
  auth.onIdTokenChanged(user => {
    if (user) {
      config.headers.Authorization = `Bearer ${user.accessToken}`
    }
  })
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

// Add a response interceptor
HttpClient.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response
}, function (error) {
  if (error.response?.status === 401) {
    signOut()
  }
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error)
})
