import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

export const userSlice = createSlice({
  name: 'user',
  initialState: { loading: true },
  reducers: {
    setUser: (state, action) => {
      setUserToStorage(action.payload)
      return ({
        ...state, ...action.payload
      })
    },
    checkUser: (state) => {
      if (getUserFromStorage()) {
        return ({
          ...state, ...getUserFromStorage()
        })
      }
      return state
    },
    unsetUser: () => {
      window.sessionStorage.removeItem('user')
      return initialState
    }
  }
})

// Action creators are generated for each case reducer function
export const { setUser, checkUser, unsetUser } = userSlice.actions

export default userSlice.reducer

const setUserToStorage = user => window.sessionStorage.setItem('user', JSON.stringify(user))

const getUserFromStorage = () => JSON.parse(window.sessionStorage.getItem('user'))
